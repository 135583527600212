/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-01-31",
    versionChannel: "nightly",
    buildDate: "2024-01-31T00:15:47.818Z",
    commitHash: "958f502cb54d413990b2044943a964c2abb410f0",
};
